<template>
  <v-container class="py-12">
    <v-row>
      <v-col
        cols="12"
        lg="6"
        md="6"
        sm="12"
        v-for="(item, index) in projSelect"
        :key="index"
      >
        <v-card outlined>
          <v-img height="400px" :src="require(`../../assets/${item.imgsrc}`)">
          </v-img>
          <v-card-title class="subtitle-1">{{
            $t(`project.${item.title}`)
          }}</v-card-title>
          <v-card-subtitle class="pb-0">
            {{ item.date }}
          </v-card-subtitle>

          <v-card-text class="text--primary pt-2 caption">
            <div>{{ $t(`project.${item.description}`) }}</div>
          </v-card-text>

          <v-card-actions class="pa-4">
            <v-btn
              outlined
              color="primary"
              text
              :to="`/projectInfo=${prop}&${imgsrc}&${JSON.stringify(item)}`"
            >
              {{ $t("readmore") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import json from "../../data/projects.json";
export default {
  props: ["prop", "imgsrc"],
  data() {
    return {
      projects: json,
      projSelect: [],
    };
  },
  created() {
    if (this.prop == "Assyriaphysicaleducation") {
      this.projSelect = this.projects.projects.Assyriaphysicaleducation;
    } else if (this.prop == "BodourResidentialCompoundBaghdad") {
      this.projSelect = this.projects.projects.BodourResidentialCompoundBaghdad;
    } else if (this.prop == "TheBowlingProject") {
      this.projSelect = this.projects.projects.TheBowlingProject;
    } else if (this.prop == "displayScreen") {
      this.projSelect = this.projects.projects.displayScreen;
    } else if (this.prop == "AlSalamResidentialCompound") {
      this.projSelect = this.projects.projects.AlSalamResidentialCompound;
    } else if (this.prop == "Shopping Center") {
      this.projSelect = this.projects.projects.ShoppingCenter;
    } else if (this.prop == "HematologyCenterMedicalCity") {
      this.projSelect = this.projects.projects.HematologyCenterMedicalCity;
    } else if (this.prop == "fiberCable") {
      this.projSelect = this.projects.projects.fiberCable;
    } else if (this.prop == "SouthernLinkProjectHolyKarbala") {
      this.projSelect = this.projects.projects.SouthernLinkProjectHolyKarbala;
    } else if (this.prop == "Universities") {
      this.projSelect = this.projects.projects.Universities;
    } else if (this.prop == "AssyriaUniversity") {
      this.projSelect = this.projects.projects.AssyriaUniversity;
    } else if (this.prop == "Firefighting") {
      this.projSelect = this.projects.projects.Firefighting;
    } else if (this.prop == "electricity") {
      this.projSelect = this.projects.projects.electricity;
    } else if (this.prop == "electricityAlIsraa") {
      this.projSelect = this.projects.projects.electricityAlIsraa;
    } else if (this.prop == "BabylonMall") {
      this.projSelect = this.projects.projects.BabylonMall;
    } else if (this.prop == "PhantomLab") {
      this.projSelect = this.projects.projects.PhantomLab;
    } else if (this.prop == "AssyriaCollegeLightingProject") {
      this.projSelect = this.projects.projects.AssyriaCollegeLightingProject;
    }
  },
  methods: {},
};
</script>

<style></style>
